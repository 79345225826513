.project-card-view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .project-cards-container {
    display: flex;            /* Use Flexbox for layout */
    flex-wrap: wrap;         /* Allow wrapping of cards */
    justify-content: space-between; /* Space cards evenly */
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px; /* Adjust the gap between icons */
  padding: 0; /* Remove padding if any */
}

.tech-icons {
  font-size: 2rem;
  color: #ffffff; /* Adjust color as needed */
}
  
.project-card-view .card-img {
  width: 100%;
  height: 300px;         /* Fixed height */
  object-fit: contain;   /* Show entire image without cropping */
  border-radius: 10px;   /* Optional: Adds a rounded corner effect */
}

.project-card-view .card-subtitle {
  font-size: 1.2em; /* Adjust font size */
  color: gray !important;  /* Use !important to override other styles */
  text-align: center; /* Center the subtitle */
}
  
  .project-card-view .card-body {
    flex-grow: 1; /* Makes the body take up the remaining space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /*Ensures description and buttons are spaced*/
  }
  
  .project-card-view .card-title {
    font-size: 1.8em; /* Increase the font size */
    font-weight: bold; /* Make the text bold */
    color: #6f42c1; /* Set a distinct color (purple in this case) */
    text-align: center; /* Center-align the title */
    margin-bottom: 15px; /* Add spacing below the title */
    text-transform: uppercase;/* Make the title uppercase*/
    letter-spacing: 1.5px;           /* Add spacing between letters */
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3); /* Add a subtle shadow effect */
  }
  
  .project-card-view .card-text {
    flex-grow: 1; /* Ensures that text content stretches to fill the available space */
  }
  
  .project-card-view .btn {
    margin-top: auto; /* Pushes the buttons to the bottom of the card */
  }

  .project-card-view img {
    width: 100%;
    height: 300px; /* Adjust the height to your desired size */
    object-fit: cover; /* Ensures the image maintains its aspect ratio */
    border-radius: 10px; /* Optional, for rounded corners */
  }

  .image-wrapper {
    width: 100%;            /* Full width of the card */
    height: 200px;          /* Fixed height */
    overflow: hidden;       /* Hide overflow to maintain layout */
}

.skills-box {
  margin: 10px 0;
} 
  